import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  IconButton,
  useDisclosure,
  Link as ChakraLink,
  Stack,
  Container,
  Text,
  CloseButton
} from "@chakra-ui/react";
import Image from "next/image";
import Logo from "src/img/logo.svg";
import Link from "next/link";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { hasCookie, setCookie } from "cookies-next";
import Search from "./MainPage/Search";
import "instantsearch.css/themes/reset.css";
import "instantsearch.css/themes/satellite.css";
import dynamic from "next/dynamic";
import { configFile } from "@/config";


interface Props {
  menu: any;
  cookies: any;
}
const MobileNav = dynamic(() => import("@/components/MobileNav"));
const DesktopNav = dynamic(() => import("@/components/DesktopNav"));

function HeaderMenu({ menu, cookies }: Props) {
  const { isOpen, onToggle } = useDisclosure();
  const [isCookieBanner, seIsCookieBanner] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<Boolean>();


  useEffect(() => {
    if (!hasCookie("notFirstVisit")) {
      seIsCookieBanner(true);
    }

    setIsMobile(window.innerWidth <= 1024 ? true : false);

    const cookiesData = async () => {
      cookies.map((item: any) => {
        if (!hasCookie(`${item.attributes.name}`)) {
          const maxAge = () => {
            if (
              item.attributes.duration.days *
              item.attributes.duration.hours *
              item.attributes.duration.minutes ===
              0
            ) {
              return new Date(
                new Date().setFullYear(new Date().getFullYear() + 1)
              ).getTime();
            } else {
              return (
                item.attributes.duration.days *
                item.attributes.duration.hours *
                item.attributes.duration.minutes
              );
            }
          };
          setCookie(
            `${item.attributes.name}`,
            `${item.attributes.description}`,
            { path: "/", maxAge: maxAge(), sameSite: "strict" }
          );
        }
      });
    };

    cookiesData();
  }, []);

  const closeCookieBanner = () => {
    seIsCookieBanner(false);
    setCookie("notFirstVisit", true, {
      path: "/",
      maxAge: new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      ).getTime(),
    });
  };

  return (
    <>
      {isCookieBanner && (
        <Container
          as="section"
          pb="10px"
          zIndex="999"
          position="fixed"
          bottom="0"
          minWidth="100%"
        >
          <Box
            bg={configFile.primaryAccentColor}
            color="#fff"
            p={{ base: "4", md: "3" }}
            py={{ base: "3", md: "5" }}
            position="relative"
            borderRadius="xl"
            margin="0 auto"
            width="90vw"
            maxWidth="1216px"
          >
            <Stack
              direction={{ base: "column", md: "row" }}
              justify="center"
              spacing={{ base: "0.5", md: "1.5" }}
              pe={{ base: "4", sm: "0" }}
            >
              <Text color="#fff" fontWeight="medium">
                This site is using cookies.
              </Text>
              <Text color="#fff">
                Read our{" "}
                <ChakraLink prefetch={false} as={Link} href="/privacy-notice">
                  Privacy notice
                </ChakraLink>
              </Text>
            </Stack>
            <CloseButton
              onClick={closeCookieBanner}
              position="absolute"
              right="2"
              top={{ base: "2", md: "4" }}
            />
          </Box>
        </Container>
      )}
      <Box
        className="nav-wrapper"
        zIndex="999"
        position="fixed"
        backgroundColor="#fff"
        top="0px"
        width="100%"
      >
        <Flex
          maxW="1216px"
          width="95%"
          m="0 auto"
          maxH={'80px'}
          minH={'80px'}
          justifyContent="space-between"
          position='relative'
          alignItems={'center'}
        >
          <Flex align="center" columnGap="84px">
            <Link prefetch={false} href="/">
              <Image
                src={Logo}
                alt="Ecto"
                width="186"
                height="55"
                priority
                placeholder="blur"
                blurDataURL={Logo.src}
                style={{ width: 'auto', height: 'auto' }}
              />
            </Link>
          </Flex>

          {menu && (
            <>
              {isMobile ? <MobileNav isOpen={isOpen} item={menu} /> : <DesktopNav menu={menu} />}
            </>
          )}

          <Box display={{ base: "none", lg: "block" }}>
            <Search />
          </Box>
          <IconButton
            onClick={onToggle}
            display={{ lg: "none" }}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        {isOpen && <Box overflowY={'hidden'} onClick={onToggle} position={'fixed'} w={'100vw'} h='100vh' bgColor='blackAlpha.600' zIndex='2' />}
      </Box>
    </>
  );
}

export default HeaderMenu;
