import React from "react";
import useTranslation from "next-translate/useTranslation";

import {
  Stack,
  Text,
  Link as ChakraLink,
  Box,
  Avatar,
  Tag,
} from "@chakra-ui/react";

import Link from "next/link";
import { BlogPostsMainData } from "@/Types/types";
import { formatDate } from "../helpers/formatDate";

import { configFile } from "@/config";

interface Props {
  item?: BlogPostsMainData;
}

function RecentlyPostCard({ item }: Props) {
  const { url, title, readingTime, updatedAt, previewText } = item!.attributes;
  const { t } = useTranslation("common");
  const locale = useTranslation().lang;
  const date = formatDate(updatedAt, locale);

  return (
    <Box
      padding="8px"
      width={{ base: "100%", sm: "calc(50% - 16px)" }}
      maxW="400px"
      bgColor="#fff"
      borderRadius="8px"
      top="0"
      position="relative"
      transition="top 250ms ease, box-shadow 250ms ease"
      _hover={{
        textDecoration: "none",
        boxShadow:
          "0px 2px 4px rgb(46 41 51 / 8%), 0px 5px 10px rgb(71 63 79 / 16%)",
        top: "-3px",
      }}
      boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"
    >
      <Stack
        padding="16px"
        justify="flex-start"
        align="flex-start"
        spacing="24px"
        alignSelf="stretch"
      >
        <Stack
          justify="flex-start"
          align="flex-start"
          spacing="16px"
          alignSelf="stretch"
        >
          <Stack justify="flex-start" align="flex-start" alignSelf="stretch">
            <ChakraLink prefetch={false}
              as={Link}
              href={`categoria/${item?.attributes?.category?.data?.[0]?.attributes?.slug || ""
                }`}
              _hover={{ textDecoration: "none" }}
            >
              <Tag
                bgColor={
                  item?.attributes?.category?.data?.[0]?.attributes?.color ||
                  "#000"
                }
                color="#fff"
                textTransform="uppercase"
                mb="16px"
              >
                {item?.attributes?.category?.data?.[0]?.attributes?.title ||
                  "No Category"}
              </Tag>
            </ChakraLink>
            <ChakraLink prefetch={false}
              lineHeight="1.4"
              fontWeight="bold"
              fontSize="20px"
              color="gray.800"
              alignSelf="stretch"
              href={url}
              as={Link}
              _hover={{ textDecoration: "none" }}
            >
              {title}
            </ChakraLink>
          </Stack>
          <Text
            lineHeight="1.5"
            fontWeight="regular"
            fontSize="16px"
            color="gray.600"
            alignSelf="stretch"
          >
            {previewText}
          </Text>
        </Stack>
        <Stack
          alignSelf="stretch"
          direction="row"
          justify="flex-start"
          align="center"
          spacing="16px"
        >
          <ChakraLink prefetch={false} as={Link} href="#">
            <Avatar
              width="48px"
              height="48px"
              name={item!.attributes.author.data.attributes.name}
              src={
                item!.attributes.author.data.attributes.img.data.attributes.url
              }
            />
          </ChakraLink>
          <Box>
            <ChakraLink prefetch={false}
              lineHeight="1.43"
              fontWeight="medium"
              fontSize="14px"
              color="gray.800"
              as={Link}
              href={`/perfil/${item!.attributes.author.data.attributes.url}`}
              _hover={{
                textDecoration: "none",
                color: configFile.primaryAccentColor,
              }}
            >
              <Text>{item!.attributes.author.data.attributes.name}</Text>
            </ChakraLink>
            <Text
              lineHeight="1.33"
              fontWeight="regular"
              fontSize="12px"
              color="gray.600"
              alignSelf="stretch"
            >
              {date} • {readingTime} {t("article.minRead")}
            </Text>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}

export default RecentlyPostCard;
