import React from "react";
import useTranslation from "next-translate/useTranslation";

import {
  Box,
  Text,
  Heading,
  Flex,
  Button,
} from "@chakra-ui/react";

import { BlogPostsMainData, CategoryData } from "@/Types/types";
import CategoryListItem from "../CategoryListItem";
import RecentlyPostCard from "../RecentlyPostCard";

import { configFile } from "@/config";

interface Props {
  data: BlogPostsMainData[];
  sponsorImg?: string;
  sponsorLink?: string;
  topics: Record<"attributes", CategoryData>[];
}

function RecentPosts({ data, topics }: Props) {
  const [showMore, setShowMore] = React.useState(false);
  const { t } = useTranslation("common");

  function handleShowMore(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    setShowMore((prevState) => !prevState);
  }

  return (
    <Box
      as="section"
      width="100%"
      bgColor="gray.50"
      paddingBottom={{ base: "60px", lg: "80px" }}
      marginTop="80px"
      pt={'32px'}
      position="relative"
    >
      <Box maxWidth="1216px" minH={'43.188px'} margin="0 auto" width="90%" mb="32px">

        <Heading as="h2">{t("homepage.title")}</Heading>

      </Box>
      <Flex
        mt={'32px'}
        maxWidth="1216px"
        margin="0 auto"
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDirection={{ base: "column", lg: "row" }}
        columnGap="64px"
        width="90%"
      >
        <Flex
          flexWrap="wrap"
          columnGap="32px"
          rowGap="32px"
          width="100%"
          alignItems="stretch"
          justifyContent="flex-start"
          mb={{ base: "32px", lg: "0" }}
        >
          {data &&
            data.map((item: BlogPostsMainData, index: number) => (
              <RecentlyPostCard key={index} item={item} />
            ))}
        </Flex>
        <Flex
          width="100%"
          maxW={{ base: "none", lg: "320px" }}
          columnGap={{ base: "0", md: "32px", lg: "0" }}
          alignItems="space-between"
          rowGap="64px"
          flexDirection={{ base: "column", md: "row", lg: "column" }}
        >
          <Box width="100%">
            <>
              <Text fontWeight="700" fontSize="18px">
                {t("homepage.topics.title")}
              </Text>
              {topics
                .slice(0, showMore ? topics.length : 4)
                .map((item, index) => (
                  <CategoryListItem key={index} item={item.attributes} />
                ))}
              <Button
                aria-label={showMore ? "view less" : "view more"}
                borderRadius="8px"
                width="100%"
                display="flex"
                overflow="hidden"
                alignSelf="stretch"
                boxShadow="base"
                mt="12px"
                bgColor={configFile.secondaryAccentColor}
                color={configFile.tertiaryAccentColor}
                sx={{ ":hover p": { color: "black" } }}
                _hover={{ bgColor: "gray.100", color: "black" }}
                onClick={handleShowMore}
              >
                {showMore
                  ? t("homepage.topics.buttonLess")
                  : t("homepage.topics.buttonMore")}
              </Button>
            </>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}

export default RecentPosts;
