import React from "react";
import useTranslation from "next-translate/useTranslation";
import { Box, Link as ChakraLink, Heading, Flex } from "@chakra-ui/react";
import Link from "next/link";
import { BlogPostsMainData } from "@/Types/types";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import HorizontalCardImg from "../HorizontalCardImg";

import { configFile } from "@/config";

interface Props {
  category: string;
  url: string;
  articles: BlogPostsMainData[];
}

function CategoryPosts({ category, url, articles }: Props) {
  const { t } = useTranslation("common");

  return (
    <Box
      as="section"
      width="100%"
      paddingBottom={{ base: "60px", lg: "80px" }}
      paddingTop={{ base: "60px", lg: "80px" }}
      position="relative"
      bgColor="gray.50"
    >
      <Flex
        maxWidth="1216px"
        margin="0 auto"
        width="90%"
        mb="32px"
        justifyContent="space-between"
        alignItems="center"
        flexDir={{ base: "column", md: "row" }}
      >
        <ChakraLink prefetch={false}
          _hover={{ textDecor: "none" }}
          as={Link}
          href={`categoria/${url}`}
        >
          <Heading
            as="h2"
            width="100%"
            textAlign={{ base: "center", md: "left" }}
          >
            {category}
          </Heading>
        </ChakraLink>
        <ChakraLink prefetch={false}
          as={Link}
          href={`categoria/${url}`}
          color={configFile.primaryAccentColor}
          fontSize="20px"
          fontWeight="600"
          minW="180px"
          alignItems="center"
          columnGap="3px"
          display={{ base: "none", md: "flex" }}
        >
          {t("homepage.articles.button")}
          <ArrowForwardIcon position="relative" top="2px" />
        </ChakraLink>
      </Flex>
      <Flex
        maxWidth="1216px"
        margin="0 auto"
        width="90%"
        columnGap="32px"
        rowGap="32px"
        flexWrap="wrap"
        justifyContent="flex-start"
      >
        {articles.length &&
          articles.map((item, index) => (
            <HorizontalCardImg key={index} post={item} />
          ))}
      </Flex>
    </Box>
  );
}

export default CategoryPosts;
