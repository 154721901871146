import { SearchIcon } from "@chakra-ui/icons";
import { Box, useDisclosure } from "@chakra-ui/react";
import dynamic from "next/dynamic";

function Search() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const SearchModal = dynamic(() => import("@/components/SearchModal"), {
    ssr: false,
  });

  return (
    <>
      <Box
        onClick={onOpen}
        width={{ lg: "350px" }}
        maxW={{ base: '250px' }}
        height="40px"
        mr={{ base: '32px', md: '0' }}
        bgColor="gray.100"
        border="none"
        color="gray.400"
        px="16px"
        py='8px'
        justifyContent="flex-start"
        borderRadius="6px"
        alignItems="center"
        _hover={{ cursor: "pointer" }}
        display="flex"
      >
        <SearchIcon mr="8px" width="20px" color="gray.400" />
        Pesquise em nosso site.
      </Box>
      {isOpen && <SearchModal isOpen={isOpen} onClose={onClose} />}
    </>
  );
}

export default Search;
